import axios from '@axios'

export default {
  async getPdf(dosID) {
    try {
      const r = await axios.get('unterhalt/getPDF', {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
        params: {
          dosID,
          loadFiles: true,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getPdfLast(dosID) {
    try {
      const r = await axios.get('unterhalt/getPDFLast', {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
        params: {
          dosID,
          loadFiles: true,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getPdfComp(dosID) {
    try {
      const r = await axios.get('unterhalt/getPDFComp', {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
        params: {
          dosID,
          loadFiles: true,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getExcel(dosID) {
    try {
      const r = await axios.get('unterhalt/getExcel', {
        responseType: 'arraybuffer',
        headers: { Accept: 'application//vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getCalc(dosID, calcFrom, aggrWertPhase) {
    try {
      const r = await axios.get('unterhalt/getCalc', {
        params: {
          dosID,
          calcFrom,
          aggrWertPhase,
          vorlageID: 1,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getQRBill() {
    try {
      const r = await axios.get('unterhalt/getQRBill', {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
        // params: {
        // },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getBill(mandantID, datum) {
    try {
      const r = await axios.get('unterhalt/getBill', {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
        params: {
          mandantID,
          datum,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
